import { Link } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { Card } from "react-bootstrap";
import AdditionalPartners from "../components/additional-partners";
import BlogFeed from "../components/blog-feed";
import CTA from "../components/cta";
import Layout from "../components/layout";
import Seo from "../components/seo"
import OurClients from "../components/our-clients";
import OurFeaturedPartners from "../components/our-featured-partners";
import computerBackground from "../images/backrounds/1856.png";
import LaptopIcon from "../icons/laptop.svg";
import ServerIcon from "../icons/server.svg";
import PencilIcon from "../icons/pencil.svg";
import NeedAQuote from "../components/need-a-quote";

const Index = ({location}) => {
  return (
    <Layout>
      <Seo
        pathName={location.pathname}
        title="Services"
        description="729 Solutions is your one-stop-shop for Software Development and UI/UX Design Services. We can do it all for you under one roof."
      />
      <section>
        <div
          className="hero mb-5"
          style={{
            backgroundImage: `url(${computerBackground})`,
          }}
        >
          <div className="hero-title bg-primary-transparent">
            <h1 className="text-white">
              All The Things You Think You Need, And The Kitchen Sink
            </h1>
            <p className="text-white">
              We’re designers, developers, project managers, and solutions
              architects. We are problem solvers who come up with new ways to
              make technology faster, safer, and easier. We are builders who
              create experiences and products that people love. We integrate
              complex systems in the simplest ways possible. We help
              fast-growing companies and their customers do more.
            </p>
            <p className="text-white">
              We’ve been engineering amazing technology for 15 years and we want
              to do it for you.
            </p>
            <CTA
              href="/contact-us/"
              text="Tell Us About Your Project"
              className="btn btn-secondary-light"
            />
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-4">
              <AnchorLink to="#app-architecture-engineering">
                <Card className="card custom-cards card-borderless mb-5 py-4">
                  <LaptopIcon
                    className="m-auto"
                    width={144}
                    height={144}
                    alt="Application Architecture"
                  />
                  <Card.Body>
                    <Card.Title as="h3">
                      Application architecture and Engineering
                    </Card.Title>
                  </Card.Body>
                </Card>
              </AnchorLink>
            </div>
            <div className="col-12 col-lg-4">
              <AnchorLink to="#system-integration">
                <Card className="card custom-cards card-borderless mb-5 py-4">
                  <ServerIcon
                    className="m-auto"
                    width={144}
                    height={144}
                    alt="Systems Integrations"
                  />
                  <Card.Body>
                    <Card.Title as="h3">
                      Systems Integrations, Devops, Hosting, And Managed
                      Services
                    </Card.Title>
                  </Card.Body>
                </Card>
              </AnchorLink>
            </div>
            <div className="col-12 col-lg-4">
              <Link to="/design-services/">
                <Card className="card custom-cards card-borderless mb-5 py-4">
                  <PencilIcon
                    className="m-auto"
                    width={144}
                    height={144}
                    alt="Creative Process"
                  />
                  <Card.Body>
                    <Card.Title as="h3">
                      Creative Process, Planning, Multimedia, And Graphic Design
                    </Card.Title>
                  </Card.Body>
                </Card>
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row" id="app-architecture-engineering">
            <div className="col-12 col-lg-6">
              <StaticImage
                src="../images/backrounds/hire-a-developer-scaled.jpg"
                alt="Application Architecture"
              />
            </div>
            <div className="col-12 col-lg-6 mt-5 mt-lg-0">
              <h2 className="mb-2">Application Architecture And Engineering</h2>
              <p>
                At the heart of 729 is a dedicated development shop delivering
                clean and elegant code in these areas:
              </p>
              <ul className="text-primary mt-2">
                <li className="mb-2">
                  <span className="text-dark">Web Application Development</span>
                </li>
                <li className="mb-2">
                  <span className="text-dark">Website Development</span>
                </li>
                <li className="mb-2">
                  <span className="text-dark">
                    Application Program Interface (API) Development
                  </span>
                </li>
                <li className="mb-2">
                  <span className="text-dark">Mobile Applications</span>
                </li>
                <li className="mb-2">
                  <span className="text-dark">AI Consulting</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container my-6">
          <div className="row bg-dark py-5">
            <div className="col-12 col-lg-4">
              <h3 className="text-secondary-light">Front &amp; Back End</h3>
              <div className="text-white">
                <p>
                  HTML
                  <br />
                  CSS
                  <br />
                  C / C++ Javascript
                  <br />
                  XML-based languages
                  <br />
                  Groovy
                  <br />
                  Java
                  <br />
                  Javascript (Node JS)
                  <br />
                  NET (C#, VB)
                  <br />
                  Objective-C
                  <br />
                  PHP
                  <br />
                  React
                  <br />
                  Ruby
                  <br />
                  Swift
                  <br />
                  Windows desktop apps <br />
                  Artificial Intelligence <br />
                  API Integration with large language models <br />
                </p>
              </div>
            </div>
            <div className="col-12 col-lg-4">
              <h3 className="text-secondary-light">
                General Framework Capabilities
              </h3>
              <div className="text-white">
                <p>
                  ASP.Net MVC
                  <br />
                  ASP.Net
                  <br />
                  ASP.Net Web API
                  <br />
                  CoffeeScript SailsJS
                  <br />
                  Drupal JQuery
                  <br />
                  ExpressJS
                  <br />
                  Ext JS
                  <br />
                  JBoss CakePHP
                  <br />
                  Ruby On Rails
                  <br />
                  Spring Boot
                  <br />
                  WCF
                  <br />
                  Web Forms
                  <br />
                  Windows Forms
                  <br />
                  WordPress
                  <br />
                  WPF
                  <br />
                </p>
              </div>
            </div>
            <div className="col-12 col-lg-4">
              <h3 className="text-secondary-light">Platforms &amp; Products</h3>
              <div className="text-white">
                <p>
                  Customer Support
                  <br /> CRMs
                  <br />
                  Security
                  <br />
                  E-Commerce
                  <br />
                  Education
                  <br />
                  Loan, Financial Technology
                  <br />
                  Microsoft
                  <br />
                  Netsuite
                  <br />
                  Salesforce
                  <br />
                  SAP
                  <br />
                  Oracle
                  <br />
                  OpenAI/ChatGPT
                  <br />
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <OurFeaturedPartners className="my-6" />
      <AdditionalPartners className="my-6" />
      <OurClients className="my-6" />
      <section className="mb-6">
        <div className="container">
          <div className="row" id="system-integration">
            <div className="col-12 col-lg-6 order-lg-2">
              <StaticImage
                src="../images/backrounds/datacenter.png"
                alt="Devops and Hosting"
              />
            </div>
            <div className="col-12 col-lg-6 mt-5 mt-lg-0 order-lg-1">
              <h2>
                Systems Integrations, Devops, Hosting, and Managed Services
              </h2>
              <p>
                729 Solutions has extensive experience connecting platforms,
                tools, and databases according to complex business rules and
                data security requirements. If your technology needs connecting,
                monitoring, tuning, and updating let us help you.
              </p>
            </div>
            <div className="col-12 col-lg-6 mt-5 order-lg-3">
              <h2>Systems integration services</h2>
              <ul className="text-primary mt-2">
                <li className="mb-2">
                  <span className="text-dark">
                    API &amp; API middleware for SAAS tools (Zendesk, Salesforce,
                    etc.)
                  </span>
                </li>
                <li className="mb-2">
                  <span className="text-dark">Database integrations</span>
                </li>
                <li className="mb-2">
                  <span className="text-dark">Complex business workflows</span>
                </li>
                <li className="mb-2">
                  <span className="text-dark">
                    Better data integrations to reduce human repetition (excel,
                    reporting, etc.)
                  </span>
                </li>
                <li className="mb-2">
                  <span className="text-dark">
                    Connectors, Middleware, Wrappers, and Applications
                  </span>
                </li>
              </ul>
            </div>
            <div className="col-12 col-lg-6 mt-5 order-lg-5">
              <h2>Maintenance and operations</h2>
              <ul className="text-primary mt-2">
                <li className="mb-2">
                  <span className="text-dark">
                    Application support and monitoring
                  </span>
                </li>
                <li className="mb-2">
                  <span className="text-dark">
                    Infrastructure consulting and managed services
                  </span>
                </li>
                <li className="mb-2">
                  <span className="text-dark">
                    Scheduled WordPress Maintenance (CMS, Theme, Plugin, and
                    Content Updates)
                  </span>
                </li>
                <li className="mb-2">
                  <span className="text-dark">
                    Database support, issue triage, and performance monitoring
                  </span>
                </li>
                <li className="mb-2">
                  <span className="text-dark">Security</span>
                </li>
              </ul>
            </div>
            <div className="col-12 col-lg-6 mt-5 order-lg-7">
              <h2>General cloud competencies</h2>
              <ul className="text-primary mt-2">
                <li className="mb-2">
                  <span className="text-dark">Microsoft workloads</span>
                </li>
                <li className="mb-2">
                  <span className="text-dark">
                    Application performance, tuning, and monitoring (New Relic
                    partner)
                  </span>
                </li>
                <li className="mb-2">
                  <span className="text-dark">
                    Moving legacy applications to the cloud (refactoring /
                    microservices and containers)
                  </span>
                </li>
                <li className="mb-2">
                  <span className="text-dark">Automation (Chef partner)</span>
                </li>
                <li className="mb-2">
                  <span className="text-dark">AWS Skills</span>
                </li>
                <li className="mb-2">
                  <span className="text-dark">Amazon CloudFront</span>
                </li>
                <li className="mb-2">
                  <span className="text-dark">Aurora</span>
                </li>
                <li className="mb-2">
                  <span className="text-dark">Lambda</span>
                </li>
                <li className="mb-2">
                  <span className="text-dark">API Gateway Dynamo</span>
                </li>
                <li className="mb-2">
                  <span className="text-dark">
                    Server and Database Migration
                  </span>
                </li>
                <li className="mb-2">
                  <span className="text-dark">RDS for Postgres</span>
                </li>
              </ul>
            </div>
            <div className="col-12 col-lg-6 mt-5 order-4">
              <h2>Devops And Hosting</h2>
              <p>
                729 Solutions supports a wide variety of cloud-based hosting and
                maintenance (AWS, Azure, etc.) and can help build effective and
                secure Ci/CD pipelines. We are a GitHub, AWS, and New Relic
                partner with the resources and competencies to support your
                technical needs.
              </p>
            </div>
            <div className="col-12 col-lg-6 mt-5 order-lg-6">
              <h2>Platforms, databases and technologies supported</h2>
              <div className="row">
                <div className="col-4">
                  <p>
                    AWS
                    <br />
                    Azure
                    <br />
                    Chef
                  </p>
                </div>
                <div className="col-4">
                  <p>
                    Heroku
                    <br />
                    MySQL
                    <br />
                    New Relic
                    <br />
                    Oracle
                  </p>
                </div>
                <div className="col-4">
                  <p>
                    PostgreSQL
                    <br />
                    SQL Lite
                    <br />
                    SQL Server
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6 mt-5 order-lg-8">
              <h2>What Can We Do For You?</h2>
              <p>Read enough? Ready to talk about your project?</p>
              <CTA
                href="/contact-us/"
                text="Request a Call"
                className="btn btn-primary"
              />
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6">
              <StaticImage
                src="../images/backrounds/hire-a-designer-scaled.jpg"
                alt="Createtive process"
              />
            </div>
            <div className="col-12 col-lg-6 mt-5 mt-lg-0">
              <h2>
                Creative Process, Planning, Multimedia, and Graphic Design
              </h2>
              <p>
                Complimenting our development skills, the Studios team at 729
                can work with you to give you the creative edge you’re looking
                for.
              </p>
              <p>
                From the basics of defining your brand – fonts, colors, logos,
                and business cards, through complex user personas, user flow
                charts, and planning sheets. We’ll help you target your users to
                better understand and accommodate all of their unique needs.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container my-6">
          <div className="row bg-dark py-5">
            <div className="col-12 col-lg-3">
              <h3 className="text-secondary-light">Process &amp; Branding</h3>
              <div className="text-white">
                <p>
                  Brand Identity <br />
                  Business Cards
                  <br />
                  Efficiency Reviews
                  <br />
                  Icon Design
                  <br />
                  Logos
                  <br />
                  Letterhead
                  <br />
                  Project Detail Sheets
                  <br />
                  Social Media Graphics
                  <br />
                  Technical Flow
                  <br />
                  User Flow
                  <br />
                  User Experience
                  <br />
                  User Interface
                  <br />
                  User Personas
                  <br />
                </p>
              </div>
            </div>
            <div className="col-12 col-lg-3">
              <h3 className="text-secondary-light">Marketing</h3>
              <div className="text-white">
                <p>
                  Blogging
                  <br />
                  Email Graphics
                  <br />
                  Email Marketing
                  <br />
                  Inbound Marketing
                  <br />
                  Marketing Plans
                  <br />
                  PPC Ads
                  <br />
                  Social Media Graphics
                  <br />
                  Social Media Strategy
                  <br />
                  Social Media Ads
                  <br />
                </p>
              </div>
            </div>
            <div className="col-12 col-lg-3">
              <h3 className="text-secondary-light">Digital Collateral</h3>
              <div className="text-white">
                <p>
                  Animated Videos
                  <br />
                  Brochure Websites
                  <br />
                  Ecommerce Design
                  <br />
                  Help Center Design
                  <br />
                  Zendesk Designs
                  <br />
                  Interactive Demos
                  <br />
                  Landing Pages
                  <br />
                  Mobile App Design
                  <br />
                  Presentations
                  <br />
                  Web Banners
                  <br />
                  Web Forms
                  <br />
                  Website Design
                  <br />
                  WordPress Websites
                  <br />
                </p>
              </div>
            </div>
            <div className="col-12 col-lg-3">
              <h3 className="text-secondary-light">Print Collateral</h3>
              <div className="text-white">
                <p>
                  Annual Reports
                  <br />
                  Banners
                  <br />
                  Book Layout
                  <br />
                  Brochures
                  <br />
                  Case Studies
                  <br />
                  Package Design
                  <br />
                  Postcards
                  <br />
                  Posters and Flyers
                  <br />
                  Trade Show Booths
                  <br />
                  Vehicle Wraps
                  <br />
                  White Papers
                  <br />
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <NeedAQuote />
      <BlogFeed containerClassname="py-6" />
    </Layout>
  );
};

export default Index;
