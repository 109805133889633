import React from 'react'
import appleLogo from '../images/clients/apple.png'
import facebookLogo from '../images/clients/facebook.png'
import universalLogo from '../images/clients/universal.png'
import zendeskLogo from '../images/clients/zendesk.png'
import airbnbLogo from '../images/clients/airbnb.png'
import pinterestLogo from '../images/clients/pinterest.png'
import beachbodyLogo from '../images/clients/beachbody.png'

const OurClients = ({ className }) => (
  <section className={className}>
    <div className='container text-center'>
      <div className='row '>
        <div className='col'>
          <h2>Our Clients</h2>
        </div>
      </div>

      <div className='row mb-3 d-flex justify-content-center align-items-center'>
        <div className='col-lg-4 pb-2'>
          <img className='mb-4' src={appleLogo} alt='logo' style={{ maxHeight: '100px' }} />
        </div>
        <div className='col-lg-4 pb-2'>
          <img className='mb-4' src={facebookLogo} alt='logo' style={{ maxHeight: '80px' }} />
        </div>
      </div>
      <div className='row mb-3 d-flex justify-content-center align-items-center'>
        <div className='col-lg-4 pb-2'>
          <img className='mb-4' src={universalLogo} alt='logo' style={{ maxHeight: '70px' }} />
        </div>
        <div className='col-lg-4 pb-2'>
          <img className='mb-4' src={zendeskLogo} alt='logo' style={{ maxHeight: '90px' }} />
        </div>
        <div className='col-lg-4 pb-2'>
          <img className='mb-4' src={airbnbLogo} alt='logo' style={{ maxHeight: '70px' }} />
        </div>
      </div>
      <div className='row mb-3 d-flex justify-content-center align-items-center'>
        <div className='col-lg-6 pb-2'>
          <img className='mb-4' src={beachbodyLogo} alt='logo' style={{ maxHeight: '30px' }} />
        </div>
        <div className='col-lg-6 pb-2'>
          <img className='mb-4' src={pinterestLogo} alt='logo' style={{ maxHeight: '90px' }} />
        </div>
      </div>
    </div>
  </section>
)

export default OurClients
